









































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { invitationController } from './invitation-controller'
@Observer
@Component
export default class extends Vue {
  controller = invitationController
}
